import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import * as CONFIG from '../../SiteConfig';

import DotPattern from '../assets/images/patterns/dot-grid.png';
import PatternDark from '../assets/images/patterns/escheresque_ste.png';

import { device } from '../components/particles/MediaQueries';
import ApplicationContext from '../components/particles/context/ApplicationContext';

import Layout from '../components/templates/Default';

const Wrapper = styled.section`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%:
  justify-content: center;
  max-width: 100%;
  padding: 64px 32px;
  width: 100%;

  background-color: ${props =>
    props.mode === 'dark' ? props.theme.black : props.theme.white};
  background-image: url(' ${props =>
    props.mode === 'dark' ? PatternDark : DotPattern} ');
  color: ${props =>
    props.mode === 'dark' ? props.theme.white : props.theme.black};

  > * {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: 100%;
  }

  p {
     @media ${device.sm} {
      padding-right: 64px;
    }

    @media ${device.xl} {
      padding-right: 200px;
    }
  }

  table {
    margin-top: 64px;

    background-color: ${props =>
      props.mode === 'dark' ? props.theme.black : props.theme.white};
    color: ${props =>
      props.mode === 'dark' ? props.theme.white : props.theme.black};

    th {
      background-color: ${props =>
        props.mode === 'dark'
          ? props.theme.black
          : props.theme.white};
    }

    tr {
      background-color: ${props =>
        props.mode === 'dark'
          ? props.theme.black
          : props.theme.white};
      border-top: 1px solid
        ${props =>
          props.mode === 'dark'
            ? props.theme.grey800
            : props.theme.grey300};
      color: ${props =>
        props.mode === 'dark'
          ? props.theme.white
          : props.theme.black};
    }
  }
`;

const CSVFormatPage = () => {
  const { state } = useContext(ApplicationContext);

  return (
    <Wrapper mode={state.theme}>
      <h1 className="h4">CSV Format</h1>
      <p>
        This page is to help document and standardise the columns used
        in the CSV file that is imported into the mapping tool.
      </p>
      <p>
        You will find examples of accepted values, and what format
        they follow.
      </p>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Expected Value(s)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>String, example: SOT0000602</td>
          </tr>
          <tr>
            <td>LMK_KEY</td>
            <td>String, example: 1.46E+33</td>
          </tr>
          <tr>
            <td>ADDRESS1</td>
            <td>String, example: Flat 1</td>
          </tr>
          <tr>
            <td>ADDRESS2</td>
            <td>String, example: Harcol House</td>
          </tr>
          <tr>
            <td>ADDRESS3</td>
            <td>String, example: Tennyson Road</td>
          </tr>
          <tr>
            <td>POSTCODE</td>
            <td>String, example: SO17 2HF</td>
          </tr>
          <tr>
            <td>BUILDING_REFERENCE_NUMBER</td>
            <td>String, example: 2881588968</td>
          </tr>
          <tr>
            <td>CURRENT_ENERGY_RATING</td>
            <td>
              Grade as a 1 character string. Range includes:{' '}
              <span className="text--bold">A - G</span>
            </td>
          </tr>
          <tr>
            <td>POTENTIAL_ENERGY_RATING</td>
            <td>
              Grade as a 1 character string. Range includes:{' '}
              <span className="text--bold">A - G</span>
            </td>
          </tr>
          <tr>
            <td>CURRENT_ENERGY_EFFICIENCY</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>POTENTIAL_ENERGY_EFFICIENCY</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>PROPERTY_TYPE</td>
            <td>
              String, options include -
              <ul>
                <li>Flat</li>
                <li>House</li>
                <li>Maisonette</li>
                <li>Bungalow</li>
                <li>Park home</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>BUILT_FORM</td>
            <td>
              String, options include -
              <ul>
                <li>Mid-Terrace</li>
                <li>End-Terrace</li>
                <li>Semi-Detached</li>
                <li>Detached</li>
                <li>Enclosed Mid-Terrace</li>
                <li>Enclosed End-Terrace</li>
                <li>n/a</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>INSPECTION_DATE</td>
            <td>Date, example: 29/08/2014</td>
          </tr>
          <tr>
            <td>LOCAL_AUTHORITY</td>
            <td>ID, example: E06000045</td>
          </tr>
          <tr>
            <td>CONSTITUENCY</td>
            <td>ID, example: E14000955</td>
          </tr>
          <tr>
            <td>COUNTY</td>
            <td>ID, example: E14000901</td>
          </tr>
          <tr>
            <td>LODGEMENT_DATE</td>
            <td>Date, example: 29/08/2014</td>
          </tr>
          <tr>
            <td>TRANSACTION_TYPE</td>
            <td>
              String, options include -
              <ul>
                <li>rental (social)</li>
                <li>marketed sale</li>
                <li>rental (private)</li>
                <li>new dwelling</li>
                <li>non marketed sale</li>
                <li>ECO assessment</li>
                <li>assessment for green deal</li>
                <li>none of the above</li>
                <li>following green deal</li>
                <li>rental</li>
                <li>FiT application</li>
                <li>unknown</li>
                <li>RHI application</li>
                <li>not recorded</li>
                <li>
                  rental (private) - this is for backwards
                  compatibility only and should not be used
                </li>
                <li>
                  rental (social) - this is for backwards
                  compatibility only and should not be used
                </li>
                <li>Stock Condition Survey</li>
                <li>n/a</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>ENVIRONMENT_IMPACT_CURRENT</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>ENVIRONMENT_IMPACT_POTENTIAL</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>ENERGY_CONSUMPTION_CURRENT</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>ENERGY_CONSUMPTION_POTENTIAL</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>CO2_EMISS_CURR_PER_FLOOR_AREA</td>
            <td>
              Score as a 1-3 numerical value to 7 decimal places.
              Range includes:{' '}
              <span className="text--bold">
                0.0000000 - 125.0000000
              </span>
            </td>
          </tr>
          <tr>
            <td>CO2_EMISSIONS_CURRENT</td>
            <td>
              Score as a 1-2 numerical value to 1 decimal place. Range
              includes: <span className="text--bold">0.0 - 10.0</span>
            </td>
          </tr>
          <tr>
            <td>CO2_EMISSIONS_POTENTIAL</td>
            <td>
              Score as a 1-2 numerical value to 1 decimal place. Range
              includes: <span className="text--bold">0.0 - 10.0</span>
            </td>
          </tr>
          <tr>
            <td>LIGHTING_COST_CURRENT</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>LIGHTING_COST_POTENTIAL</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>HEATING_COST_CURRENT</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>HEATING_COST_POTENTIAL</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>HOT_WATER_COST_CURRENT</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>HOT_WATER_COST_POTENTIAL</td>
            <td>
              Score as a 1-4 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9999</span>
            </td>
          </tr>
          <tr>
            <td>TOTAL_FLOOR_AREA</td>
            <td>
              Score as a 1-4 numerical value to 3 decimal places.
              Range includes:{' '}
              <span className="text--bold">0.000 - 9999.999</span>
            </td>
          </tr>
          <tr>
            <td>ENERGY_TARIFF</td>
            <td>
              String, options include -
              <ul>
                <li>Single</li>
                <li>dual</li>
                <li>Unknown</li>
                <li>standard tariff</li>
                <li>off-peak 7 hour</li>
                <li>off-peak 10 hour</li>
                <li>24 hour</li>
                <li>n/a</li>
                <li>dual (24 hour)</li>
                <li>off-peak 18 hour</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINS_GAS_FLAG</td>
            <td>
              True false value. Options include:{' '}
              <span className="text--bold">Y</span> or{' '}
              <span className="text--bold">N</span>
            </td>
          </tr>
          <tr>
            <td>FLOOR_LEVEL</td>
            <td>
              String, options include -
              <ul>
                <li>Basement</li>
                <li>ground floor</li>
                <li>Ground</li>
                <li>1st</li>
                <li>mid floor</li>
                <li>2nd</li>
                <li>3rd</li>
                <li>4th</li>
                <li>5th</li>
                <li>6th</li>
                <li>7th</li>
                <li>8th</li>
                <li>9th</li>
                <li>10th</li>
                <li>11th</li>
                <li>12th</li>
                <li>13th</li>
                <li>14th</li>
                <li>15th</li>
                <li>16th</li>
                <li>17th</li>
                <li>18th</li>
                <li>19th</li>
                <li>20th</li>
                <li>21st or above</li>
                <li>top floor</li>
                <li>n/a</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>FLAT_TOP_STOREY</td>
            <td>
              True false value. Options include:{' '}
              <span className="text--bold">Y</span> or{' '}
              <span className="text--bold">N</span>
            </td>
          </tr>
          <tr>
            <td>FLAT_STOREY_COUNT</td>
            <td>
              1-2 Length numerical value. Range includes:{' '}
              <span className="text--bold">0 - 99</span>
            </td>
          </tr>
          <tr>
            <td>MAIN_HEATING_CONTROLS</td>
            <td>
              2-4 Length numerical value. Range includes:{' '}
              <span className="text--bold">10 - 2999</span>
              <span className="display--block">e.g. 2203</span>
            </td>
          </tr>
          <tr>
            <td>MULTI_GLAZE_PROPORTION</td>
            <td>
              Score as a 1-3 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 100</span>
            </td>
          </tr>
          <tr>
            <td>GLAZED_TYPE</td>
            <td>
              String, options include -
              <ul>
                <li>not defined</li>
                <li>double glazing installed during or after 2002</li>
                <li>double glazing, unknown install date</li>
                <li>double glazing installed before 2002</li>
                <li>n/a</li>
                <li>single glazing</li>
                <li>secondary glazing</li>
                <li>INVALID!</li>
                <li>double, known data</li>
                <li>triple glazing</li>
                <li>triple, known data</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>GLAZED_AREA</td>
            <td>
              String, options include -
              <ul>
                <li>Normal</li>
                <li>n/a</li>
                <li>More Than Typical</li>
                <li>Much More Than Typical</li>
                <li>Less Than Typical</li>
                <li>Much Less Than Typical</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>EXTENSION_COUNT</td>
            <td>
              Count as a single numerical value. Range includes:{' '}
              <span className="text--bold">0 - 9</span>
            </td>
          </tr>
          <tr>
            <td>NUMBER_HABITABLE_ROOMS</td>
            <td>
              Count as a 1-2 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 99</span>
            </td>
          </tr>
          <tr>
            <td>NUMBER_HEATED_ROOMS</td>
            <td>
              Count as a 1-2 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 99</span>
            </td>
          </tr>
          <tr>
            <td>LOW_ENERGY_LIGHTING</td>
            <td>
              Score as a 1-3 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 100</span>
            </td>
          </tr>
          <tr>
            <td>NUMBER_OPEN_FIREPLACES</td>
            <td>
              Count as a 1-2 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 99</span>
            </td>
          </tr>
          <tr>
            <td>HOTWATER_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>From main system</li>
                <li>Electric immersion, off-peak</li>
                <li>Electric instantaneous at point of use</li>
                <li>From main system, flue gas heat recovery</li>
                <li>From main system, no cylinder thermostat</li>
                <li>Electric immersion, standard tariff</li>
                <li>Community scheme</li>
                <li>Gas multipoint</li>
                <li>No system present: electric immersion assumed</li>
                <li>Gas boiler/circulator, no cylinder thermostat</li>
                <li>From main system, no cylinderstat</li>
                <li>Community scheme, no cylinder thermostat</li>
                <li>
                  No hot water system present - electric immersion
                  assumed
                </li>
                <li>From secondary system, no cylinder thermostat</li>
                <li>From main system, waste water heat recovery</li>
                <li>Electric immersion, dual tariff</li>
                <li>Gas instantaneous at point of use</li>
                <li>SAP05:Hot-Water</li>
                <li>
                  Electric immersion, off-peak, no cylinder thermostat
                </li>
                <li>From main system, plus solar</li>
                <li>
                  Electric immersion, standard tariff, no cylinder
                  thermostat
                </li>
                <li>From secondary system</li>
                <li>Gas boiler/circulator</li>
                <li>Community heat pump</li>
                <li>
                  Electric immersion, standard tariff, plus solar
                </li>
                <li>Electric immersion, off-peak, plus solar</li>
                <li>
                  Electric instantaneous at point of use, plus solar
                </li>
                <li>
                  From main system, no cylinder thermostat, plus solar
                </li>
                <li>Electric heat pump for water heating only</li>
                <li>
                  Electric immersion, off-peak, waste water heat
                  recovery
                </li>
                <li>Oil range cooker, no cylinder thermostat</li>
                <li>Electric heat pump, plus solar</li>
                <li>
                  From main system, plus solar, no cylinder thermostat
                </li>
                <li>
                  Electric heat pump for water heating only, no
                  cylinder thermostat
                </li>
                <li>Single-point gas water heater, off-peak</li>
                <li>Gas multipoint, plus solar</li>
                <li>From main system |O'r brif system</li>
                <li>community scheme, no cylinder thermostat</li>
                <li>Gas range cooker, no cylinder thermostat</li>
                <li>Electric immersion, off-peak, no cylinderstat</li>
                <li>
                  Electric immersion, standard tariff, waste water
                  heat recovery
                </li>
                <li>Gas boiler/circulator, plus solar</li>
                <li>Community scheme with CHP</li>
                <li>O?r brif system</li>
                <li>
                  Solid fuel range cooker, no cylinder thermostat
                </li>
                <li>Gas range cooker</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>HOT_WATER_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>HOT_WATER_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>FLOOR_DESCRIPTION</td>
            <td>
              String, example: Suspended, limited insulation (assumed)
            </td>
          </tr>
          <tr>
            <td>FLOOR_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>FLOOR_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WINDOWS_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>Single glazed</li>
                <li>Fully double glazed</li>
                <li>High performance glazing</li>
                <li>Partial double glazing</li>
                <li>Mostly double glazing</li>
                <li>Some double glazing</li>
                <li>Multiple glazing throughout</li>
                <li>Mostly secondary glazing</li>
                <li>Full secondary glazing</li>
                <li>Some secondary glazing</li>
                <li>Mostly multiple glazing</li>
                <li>Fully triple glazed</li>
                <li>Secondary glazing</li>
                <li>Partial secondary glazing</li>
                <li>Mostly double glazing</li>
                <li>Fully secondary glazing</li>
                <li>Partial multiple glazing</li>
                <li>SAP05:Windows</li>
                <li>Single glazeddouble glazing</li>
                <li>Partial triple glazing</li>
                <li>Some double glazing</li>
                <li>Fully secondary glazed</li>
                <li>Single glazedsecondary glazing</li>
                <li>Full triple glazing</li>
                <li>Single and multiple glazing</li>
                <li>Multiple glazing throughout</li>
                <li>Fully double glazed|Gwydrau dwbl llawn</li>
                <li>Some multiple glazing</li>
                <li>Gwydrau dwbl llawn</li>
                <li>Fully double glazing</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WINDOWS_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WINDOWS_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WALLS_DESCRIPTION</td>
            <td>String, example:</td>
          </tr>
          <tr>
            <td>WALLS_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WALLS_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>SECONDHEAT_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>None</li>
                <li>Room heaters, mains gas</li>
                <li>Portable electric heaters (assumed)</li>
                <li>Room heaters, electric</li>
                <li>Room heaters, smokeless fuel</li>
                <li>Room heaters, wood logs</li>
                <li>Room heaters, anthracite</li>
                <li>Room heaters, dual fuel (mineral and wood)</li>
                <li>Room heaters, coal</li>
                <li>Portable electric heaters</li>
                <li>Room heaters, LPG</li>
                <li>SAP05:Secondary-Heating</li>
                <li>Portable electric heaters(assumed)</li>
                <li>Room heaters,</li>
                <li>Room heaters, oil</li>
                <li>Room heaters, wood chips</li>
                <li>Room heaters, bioethanol</li>
                <li>Room heaters, bottled gas</li>
                <li>None|Dim</li>
                <li>
                  Electric Underfloor Heating (Standard tariff),
                  electric
                </li>
                <li>Gas/LPG boiler 1998 or later, gas</li>
                <li>Dim</li>
                <li>Room heaters, wood pellets</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>SHEATING_ENERGY_EFF</td>
            <td>String, example: N/A</td>
          </tr>
          <tr>
            <td>SHEATING_ENV_EFF</td>
            <td>String, example: N/A</td>
          </tr>
          <tr>
            <td>ROOF_DESCRIPTION</td>
            <td>String, example: N/A</td>
          </tr>
          <tr>
            <td>ROOF_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>ROOF_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEAT_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>Boiler and radiators, mains gas</li>
                <li>Electric storage heaters</li>
                <li>Community scheme</li>
                <li>Room heaters, electric</li>
                <li>Electric underfloor heating</li>
                <li>Ground source heat pump, radiators, electric</li>
                <li>
                  Air source heat pump, Systems with radiators,
                  electric
                </li>
                <li>No system present: electric heaters assumed</li>
                <li>Air source heat pump, underfloor, electric</li>
                <li>Room heaters, mains gas</li>
                <li>Boiler and underfloor heating, mains gas</li>
                <li>Air source heat pump, radiators, electric</li>
                <li>Electric storage heaters, radiators</li>
                <li>Boiler and radiators, electric</li>
                <li>
                  Portable electric heaters assumed for most rooms
                </li>
                <li>Warm air, mains gas</li>
                <li>Community scheme with CHP</li>
                <li>Warm air, electric</li>
                <li>SAP05:Main-Heating</li>
                <li>Boiler and underfloor heating, electric</li>
                <li>
                  Portable electric heating assumed for most rooms
                </li>
                <li>Room heaters, radiators, electric</li>
                <li>Room heaters, dual fuel (mineral and wood)</li>
                <li>Electric ceiling heating</li>
                <li>Air source heat pump, warm air, electric</li>
                <li>Community scheme, radiators, mains gas</li>
                <li>Water source heat pump, radiators, electric</li>
                <li>Boiler and radiators, oil</li>
                <li>Room heaters, coal</li>
                <li>
                  Boiler and radiators, dual fuel (mineral and wood)
                </li>
                <li>Ground source heat pump, underfloor, electric</li>
                <li>Boiler &amp; underfloor, mains gas</li>
                <li>Community scheme, mains gas</li>
                <li>Air source heat pump fan coil units, electric</li>
                <li>Electric ceiling heating, electric</li>
                <li>Warm air, Electricaire</li>
                <li>Boiler and radiators, LPG</li>
                <li>Community scheme with CHP and electric</li>
                <li>Community scheme with CHP, mains gas</li>
                <li>Ground source heat pump, warm air, electric</li>
                <li>Electric ceiling, electric</li>
                <li>Community scheme, radiators, waste combustion</li>
                <li>Community scheme with CHP and geothermal</li>
                <li>
                  Air source heat pump, Underfloor heating, pipes in
                  screed above insulation, electric
                </li>
                <li>Room heaters, oil</li>
                <li>Boiler and radiators,</li>
                <li>Boiler and radiators, anthracite</li>
                <li>
                  Boiler and radiators, |Bwyler a rheiddiaduron,
                  |mains gas|nwy prif gyflenwad
                </li>
                <li>Boiler and radiators, coal</li>
                <li>Boiler and radiators, wood logs</li>
                <li>Room heaters, LPG</li>
                <li>Community scheme with CHP and mains gas</li>
                <li>Room heaters, wood logs</li>
                <li>Bwyler a rheiddiaduron, nwy prif gyflenwad</li>
                <li>
                  Electric Underfloor Heating (Standard tariff),
                  electric
                </li>
                <li>Community scheme, oil</li>
                <li>Room heaters, smokeless fuel</li>
                <li>Boiler and radiators, smokeless fuel</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEAT_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEAT_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEATCONT_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>Programmer, room thermostat and TRVs</li>
                <li>Programmer and room thermostat</li>
                <li>Manual charge control</li>
                <li>Flat rate charging, TRVs</li>
                <li>Time and temperature zone control</li>
                <li>No thermostatic control of room temperature</li>
                <li>
                  No time or thermostatic control of room temperature
                </li>
                <li>Programmer, TRVs and bypass</li>
                <li>Flat rate charging, programmer and TRVs</li>
                <li>Programmer and appliance thermostats</li>
                <li>Automatic charge control</li>
                <li>None</li>
                <li>Room thermostat only</li>
                <li>
                  Charging system linked to use of community heating,
                  programmer and TRVs
                </li>
                <li>Appliance thermostats</li>
                <li>
                  Flat rate charging, programmer, no room thermostat
                </li>
                <li>Flat rate charging, room thermostat only</li>
                <li>Programmer and at least two room thermostats</li>
                <li>TRVs and bypass</li>
                <li>Programmer, no room thermostat</li>
                <li>
                  Charging system linked to use of community heating,
                  programmer and room thermostat
                </li>
                <li>Celect-type controls</li>
                <li>Programmer and room thermostats</li>
                <li>Temperature zone control</li>
                <li>
                  Flat rate charging, programmer and room thermostat
                </li>
                <li>Programmer, TRVs and boiler energy manager</li>
                <li>
                  Flat rate charging, no thermostatic control of room
                  temperature
                </li>
                <li>Room thermostats only</li>
                <li>SAP05:Main-Heating-Controls</li>
                <li>
                  Charging system linked to the use of community
                  heating, prog and TRVs
                </li>
                <li>
                  Controls for high heat retention storage heaters
                </li>
                <li>Unit charging, programmer and TRVs</li>
                <li>
                  Charging system linked to use of community heating,
                  room thermostat only
                </li>
                <li>Flat rate charging, programmer and room stat</li>
                <li>No time or thermostatic control of room temp</li>
                <li>
                  Charging system linked to the use of community
                  heating, programmer and TRVs
                </li>
                <li>Flat rate charging*, programmer and TRVs</li>
                <li>
                  Flat rate charging, no stat control of room
                  temperature
                </li>
                <li>Programmer and at least 2 room thermostats</li>
                <li>Programmer, TRVs and flow switch</li>
                <li>Not relevant (supplies DHW only)</li>
                <li>
                  Charging system linked to use of community heating,
                  TRVs
                </li>
                <li>Flat rate charging, no thermostatic control</li>
                <li>
                  Flat rate charging, programmer and at least two room
                  thermostats
                </li>
                <li>
                  Charging system linked to use of communit heating,
                  programmer and TRVs
                </li>
                <li>
                  Charging system linked to use of communit heating,
                  programmer and room thermostat
                </li>
                <li>Rhaglennydd, thermostat ystafell a TRVs</li>
                <li>Programmer, roomstat and TRVs</li>
                <li>
                  Charging system linked to use of communit heating,
                  TRVs
                </li>
                <li>
                  Charging system linked to use of communit heating,
                  room thermostat only
                </li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEATC_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAINHEATC_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>LIGHTING_DESCRIPTION</td>
            <td>
              String, options include -
              <ul>
                <li>
                  Percentage of fixed outlets in the form of a string.
                  e.g. - Low energy lighting in 62% of fixed outlets
                </li>
                <li>SAP05:Lighting</li>
                <li>No Low energy lighting</li>
                <li>No low energy lighting</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>LIGHTING_ENERGY_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>LIGHTING_ENV_EFF</td>
            <td>
              String, options include -
              <ul>
                <li>Good</li>
                <li>Average</li>
                <li>Very Poor</li>
                <li>Very Good</li>
                <li>Poor</li>
                <li>N/A</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>MAIN_FUEL</td>
            <td>
              String, options include -
              <ul>
                <li>
                  mains gas - this is for backwards compatibility only
                  and should not be used
                </li>
                <li>mains gas (not community)</li>
                <li>electricity (not community)</li>
                <li>mains gas (community)</li>
                <li>
                  electricity - this is for backwards compatibility
                  only and should not be used
                </li>
                <li>Electricity: electricity, unspecified tariff</li>
                <li>
                  To be used only when there is no heating/hot-water
                  system
                </li>
                <li>n/a</li>
                <li>electricity (community)</li>
                <li>INVALID!</li>
                <li>dual fuel - mineral + wood</li>
                <li>waste combustion (community)</li>
                <li>
                  oil - this is for backwards compatibility only and
                  should not be used
                </li>
                <li>house coal (not community)</li>
                <li>biogas (community)</li>
                <li>biomass (community)</li>
                <li>LPG (not community)</li>
                <li>
                  biogas - landfill - this is for backwards
                  compatibility only and should not be used
                </li>
                <li>oil (not community)</li>
                <li>bottled LPG</li>
                <li>LPG (community)</li>
                <li>
                  waste combustion - this is for backwards
                  compatibility only and should not be used
                </li>
                <li>anthracite</li>
                <li>
                  biomass - this is for backwards compatibility only
                  and should not be used
                </li>
                <li>smokeless coal</li>
                <li>
                  LPG - this is for backwards compatibility only and
                  should not be used
                </li>
                <li>wood logs</li>
                <li>
                  house coal - this is for backwards compatibility
                  only and should not be used
                </li>
                <li>B30D (community)</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>WIND_TURBINE_COUNT</td>
            <td>
              Single numeric value. Range includes:{' '}
              <span className="text--bold">-1 - 1</span>
            </td>
          </tr>
          <tr>
            <td>HEAT_LOSS_CORRIDOOR</td>
            <td>
              String, options include -
              <ul>
                <li>unheated corridor</li>
                <li>n/a</li>
                <li>no corridor</li>
                <li>heated corridor</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>UNHEATED_CORRIDOR_LENGTH</td>
            <td>
              Score as a 1-3 numerical value to 2 decimal places.
              Range includes:{' '}
              <span className="text--bold">0.00 - 999.99</span>
            </td>
          </tr>
          <tr>
            <td>FLOOR_HEIGHT</td>
            <td>
              Score as a 1-3 numerical value to 2 decimal places.
              Range includes:{' '}
              <span className="text--bold">0.00 - 999.99</span>
            </td>
          </tr>
          <tr>
            <td>PHOTO_SUPPLY</td>
            <td>
              Score as a 1-3 numerical value. Range includes:{' '}
              <span className="text--bold">0 - 100</span>
            </td>
          </tr>
          <tr>
            <td>SOLAR_WATER_HEATING_FLAG</td>
            <td>
              True false value. Options include:{' '}
              <span className="text--bold">Y</span> or{' '}
              <span className="text--bold">N</span>
            </td>
          </tr>
          <tr>
            <td>MECHANICAL_VENTILATION</td>
            <td>
              String, options include -
              <ul>
                <li>natural</li>
                <li>n/a</li>
                <li>mechanical, extract only</li>
                <li>mechanical, supply and extract</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>LOCAL_AUTHORITY_LABEL</td>
            <td>
              String, options include -
              <ul>
                <li>Southampton</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>CONSTITUENCY_LABEL</td>
            <td>
              String, options include -
              <ul>
                <li>Southampton, Itchen</li>
                <li>Southampton, Test</li>
                <li>Romsey and Southampton North</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>CERTIFICATE_HASH</td>
            <td>
              String, options include -
              <ul>
                <li></li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Fulladdress</td>
            <td>String, example: SO14 30F</td>
          </tr>
          <tr>
            <td>Latitude</td>
            <td>String, example: SO14 30F</td>
          </tr>
          <tr>
            <td>Longitude</td>
            <td>String, example: SO14 30F</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>
              String, options include -
              <ul>
                <li>ROOFTOP</li>
                <li>RANGE_INTERPOLATED</li>
                <li>GEOMETRIC_CENTER</li>
                <li>APPROXIMATE</li>
                <li>-1.408516</li>
                <li>-1.386227</li>
                <li>-1.387024</li>
                <li>MANUAL</li>
                <li>-1.387623</li>
                <li>-1.400907</li>
                <li>-1.384317</li>
                <li>-1.4258315</li>
                <li>-1.40557</li>
                <li>-1.368555</li>
                <li>-1.416283</li>
                <li>-1.422022</li>
                <li>-1.356446</li>
                <li>-1.443759</li>
                <li>-1.424166</li>
                <li>-1.404171</li>
                <li>-1.3600964</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              String, options include -
              <ul>
                <li>OK</li>
                <li>MANUAL</li>
                <li>ROOFTOP</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </Wrapper>
  );
};

export default () => (
  <Layout page="csvformat" align="center">
    <Helmet
      title={`${CONFIG.SITE_TITLE} | CSV Format`}
      defer={false}
    />
    <CSVFormatPage />
  </Layout>
);
